import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminPage.css";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [error, setError] = useState(null);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "http://35.237.176.102:9090/api/v1/user/get-all"
      );
      const filteredUsers = response.data.filter(
        (user) => user.role === "USER"
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Kullanıcıları yüklerken bir hata oluştu.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("token");
    if (
      !name ||
      !email ||
      !lastname ||
      !phone ||
      !address ||
      !gender ||
      !password
    ) {
      setError("Tüm alanları doldurun.");
      return;
    }

    try {
      const response = await axios.post(
        "http://35.237.176.102:9090/api/v1/user/create-user",
        {
          token,
          name,
          lastname,
          email,
          phone,
          password,
          gender,
          address,
        }
      );

      if (response.data.role === "user") {
        await fetchUsers();
        setName("");
        setLastname("");
        setEmail("");
        setPhone("");
        setPassword("");
        setRePassword("");
        setAddress("");
        setGender("");
        setError(null);
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setError("Kullanıcı oluşturulurken bir hata oluştu.");
    }
  };

  return (
    <div className="admin-container">
      <h2>Admin Sayfası</h2>

      <div className="admin-content">
        <form onSubmit={handleCreateUser} className="create-user-form">
          <h3>Kullanıcı Oluştur</h3>
          <div className="form-group">
            <label htmlFor="name">Ad</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Adı giriniz"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastname">Soyad</label>
            <input
              type="text"
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              placeholder="Soyadı giriniz"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email giriniz"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefon</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Kullanıcı telefonu giriniz"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Şifre</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Şifre belirleyiniz"
            />
          </div>
          <div className="form-group">
            <label htmlFor="repassword">Şifre Tekrar</label>
            <input
              type="password"
              id="repassword"
              value={repassword}
              onChange={(e) => setRePassword(e.target.value)}
              placeholder="Şifreyi yeniden giriniz"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Adres</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Adres giriniz"
            />
          </div>
          <div className="form-group">
            <label>Cinsiyet</label>
            <div className="gender-options">
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="E"
                  checked={gender === "E"}
                  onChange={(e) => setGender(e.target.value)}
                />
                Erkek
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="K"
                  checked={gender === "K"}
                  onChange={(e) => setGender(e.target.value)}
                />
                Kadın
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="U"
                  checked={gender === "U"}
                  onChange={(e) => setGender(e.target.value)}
                />
                Diğer
              </label>
            </div>
          </div>

          {error && <p className="error">{error}</p>}
          <button type="submit">Kullanıcı Oluştur</button>
        </form>

        <div className="user-list">
          <h3>Kayıtlı Kullanıcılar</h3>
          {users.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Ad</th>
                  <th>Soyad</th>
                  <th>Email</th>
                  <th>Telefon</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Kullanıcı bulunamadı.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
