import React from 'react';
import './ProductPage.css';

const products = [
  {
    id: 1,
    name: 'Çiçek Mum',
    description: 'Esnek, Bükülebilir, Kırılmaz ve uzun süre yanabilen el ile şekillendirilebilen Çiçek Yapımına Uygun Çiçek Mum',
    image: '/imgs/1.png',
    link: 'https://www.shopier.com/ShowProductNew/storefront.php?shop=rainbowhobby&sid=VjdkalFieWF3N250ZU4zRTBfLTFfIF8g',
    available: false,
  },
  {
    id: 2,
    name: 'Yeni Nesil Soya Wax',
    description: 'Çökme Yapmayan, Diğer wax ve parafinlere göre çok daha fazla yanma süresi olan yeni nesil Soya Wax',
    image: '/imgs/2.png',
    link: 'https://www.shopier.com/ShowProductNew/storefront.php?shop=rainbowhobby&sid=VjdkalFieWF3N250ZU4zRTBfLTFfIF8g',
    available: false,
  }
];

const ProductPage = () => {
  return (
    <div className="product-page">
      {products.map((product) => (
        <div key={product.id} className="product-card">
          <img src={product.image} alt={product.name} className="product-image" />
          <h2 className="product-name">{product.name}</h2>
          <p className="product-description">{product.description}</p>
          <a
            href={product.available ? product.link : "#"}
            target={product.available ? "_blank" : "_self"}
            rel={product.available ? "noopener noreferrer" : undefined}
            className={`buy-button ${product.available ? "" : "disabled"}`}
            disabled={!product.available}
          >
            {product.available ? "Satın Al" : "Çok Yakında"}
          </a>
        </div>
      ))}
    </div>
  );
};

export default ProductPage;
