import { createContext, useState } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = async (identity, password) => {
    try {
      const response = await axios.post("http://35.237.176.102:9090/api/v1/user/login", {
        identity: identity,
        password: password,
      });

      const data = response.data;
      setIsAuthenticated(true);

      if (data.token) {
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("name", data.name);
        sessionStorage.setItem("G",data.gender);
      }
      
      return data;
    } catch (error) {
      setIsAuthenticated(false);
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("name");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
