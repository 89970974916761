import "./App.css";
import React, { useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MainPage from "./Components/MainPage/MainPage";
import ProductPage from "./Components/ProductPage/ProductPage";
import MasterClassIntroPage from "./Components/MasterClassIntroPage/MasterClassIntroPage";
import AdminPage from "./Components/AdminPage/AdminPage";
import PrivateRoute from "./Service/PrivateRoute";
import LoginPage from "./Components/LoginPage/LoginPage";
import MasterclassPage from "./Components/MasterclassPage/MasterclassPage";
import { AuthContext } from "./Context/AuthContext";

function Welcome({ username, gender }) {
  const greeting = () => {
    if (gender === 'E') {
      return `Bey`;
    } else if (gender === 'K') {
      return `Hanım`;
    } else {
      return '';
    }
  };

  return (
    <h1>Hoşgeldiniz, {username} {greeting()}</h1>
  );
}

function App() {
  const { isAuthenticated, logout } = useContext(AuthContext);
  
  const username = sessionStorage.getItem("name");
  const gender = sessionStorage.getItem("G");

  return (
    <div className="App">
      <Helmet>
        <title>Çiçek Mum - Esnek Wax ile Mum Yapımı ve Eğitimler</title>
        <meta
          name="description"
          content="Türkiye'nin ilk esnek wax mum eğitimi ile çiçek mum yapımını öğrenin. Esnek wax ile mum yapımı ve detaylı kurs bilgileri burada."
        />
        <meta
          name="keywords"
          content="çiçek mum, esnek mum, esnek wax, mum eğitimi, Türkiye mum kursları, el yapımı mum, Flower Candle, Flower Wax, Çiçek Mum Masterclass"
        />
        <meta
          property="og:title"
          content="Çiçek Mum - Esnek Wax ile Mum Yapımı ve Eğitimler"
        />
        <meta
          property="og:description"
          content="Türkiye'nin ilk esnek wax mum eğitimi ile çiçek mum yapımını öğrenin. Esnek wax ile mum yapımı ve detaylı kurs bilgileri burada."
        />
        <meta property="og:image" content="/imgs/1.png" />
        <meta
          property="og:url"
          content="https://www.cicekmums.com/masterclass-intro"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Çiçek Mum",
              "url": "https://www.cicekmums.com",
              "logo": "https://www.cicekmums.com/imgs/rose-candle.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+905076253366",
                "contactType": "customer service"
              }
            }
          `}
        </script>
      </Helmet>

      <Router>
        <header className="App-header">
          {!isAuthenticated ? (
            <nav>
              <ul>
                <li>
                  <Link to="/">Ana Sayfa</Link>
                </li>
                <li>
                  <Link to="/products">Ürünlerimiz</Link>
                </li>
                <li>
                  <Link to="/masterclass-intro">Masterclass Nedir?</Link>
                </li>
              </ul>
            </nav>
          ) : (
            <div className="welcome-header">
              <Welcome username={username} gender={gender} />
              <button className="logout-button" onClick={logout}>Çıkış Yap</button>
            </div>
          )}
          {!isAuthenticated && (
            <h1>
              Tüm Dünya'da Viral Olan Çiçek Mum Türkiye'de! Çiçek Mum Eğitimi için
              Ön Kayıtlar Başladı..
            </h1>
          )}
        </header>

        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/masterclass-intro" element={<MasterClassIntroPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/masterclass"
            element={
              <PrivateRoute>
                <MasterclassPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-page"
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }
          />
        </Routes>

        {!isAuthenticated && (
        <section className="contact-us">
          <p>
            Eğitim ve Ücreti hakkında daha fazla bilgi almak için iletişime
            geçin..
          </p>
          <img src="/imgs/curly-arrow.png" alt="Curly Arrow" />
        </section>
        )}
        {!isAuthenticated && (
          <section className="contact-section">
            <button
              className="whatsapp-button"
              onClick={() => window.open("https://wa.me/+905076253366", "_blank")}
              aria-label="WhatsApp ile İletişime Geç"
            ></button>
            <button
              className="instagram-button"
              onClick={() =>
                window.open("https://www.instagram.com/rainbow.hobby/", "_blank")
              }
              aria-label="Instagram ile İletişime Geç"
            ></button>
          </section>
        )}

        <footer className="App-footer">
          <p>
            <a
              className="contact-instagram"
              href="https://www.instagram.com/rainbow.hobby/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram: rainbow.hobby
            </a>
          </p>
          <p>
            &copy; 2024 Developed By{" "}
            <a
              href="https://www.linkedin.com/in/muhittinulker/"
              target="_blank"
              rel="noopener noreferrer"
            >
              MuhittinU
            </a>
          </p>
          <p>
            <a
              href="https://www.shopier.com/ShowProductNew/storefront.php?shop=rainbowhobby&sid=b29TWWhkUDZYcHFvSVpRNTBfLTFfIF8g"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shopier
            </a>
          </p>
        </footer>
      </Router>
    </div>
  );
}

export default App;
