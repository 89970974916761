import React, { useContext, useState } from "react";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

const LoginPage = () => {
  const [identity, setIdentity] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(identity, password);
      const role = data.role;

      if (role === "ADMIN") {
        navigate("/admin-page");
      } else if (role === "USER") {
        navigate("/masterclass");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Giriş Yap</h2>
        <input
          type="text"
          placeholder="Telefon ya da Mailiniz"
          value={identity}
          onChange={(e) => setIdentity(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Şifre"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Giriş Yap</button>
      </form>
    </div>
  );
};

export default LoginPage;
