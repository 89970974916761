import React from "react";
import "./MasterClassIntroPage.css";

const MasterClassIntroPage = () => {
  return (
    <div className="masterclass-page">
      <div className="video-container">
        <video
          src="/videos/cicek-mum-egitim-on-video.mp4"
          controls
          disablePictureInPicture
          controlsList="nodownload"
          className="masterclass-video"
          poster="/imgs/masterclass-video-poster.jpg"
          preload="metadata"
        />
      </div>

      <div className="description-container">
        <h2>MasterClass İçeriği</h2>
        <p>
          Bu ders, çiçek mum ile mükemmel güzellikte mumdan çiçekler yapımının
          tüm aşamalarını kapsar. Eğitimde kullanılan malzemeler, teknikler ve
          ipuçları detaylıca anlatılmaktadır. Eğitim içeriğimiz;
        </p>
        <p>
          <strong>-</strong> Isı dereceleri,
          <br />
          <strong>-</strong> Renkledirme ve esans gramaj bilgileri, <br />
          <strong>-</strong> Kalıplama nasıl yapılır, <br />
          <strong>-</strong> El ile şekillendirme metodları, <br />
          <strong>-</strong> Aşama aşama tüm teknik bilgiler, <br />
          <strong>-</strong> Tedarikçi bilgileri, <br />
          <strong>-</strong> Hepsi videolu anlatımlı, <br />
          <strong>-</strong> Eğitim paketimize Yarım Kg (500 gr) Çiçek Mum
          dahildir.
        </p>
        <a
          href="https://www.shopier.com/ShowProductNew/products.php?id=29210633"
          target="_blank"
          rel="noopener noreferrer"
          className="buy-button"
        >
          Ön Kayıt
        </a>
      </div>
    </div>
  );
};

export default MasterClassIntroPage;
