import React from 'react';
import './MasterclassPage.css';

const MasterclassPage = () => {
  
  const videos = [
    { id: 1, title: "Video 1", src: "/videos/masterclass1.mp4" },
  ];

  return (
    <div className="masterclass-container">
      <h1>Masterclass Eğitim Videoları</h1>
      <div className="video-grid">
        {videos.map(video => (
          <div key={video.id} className="video-item">
            <h2>{video.title}</h2>
            <video controls width="100%">
              <source src={video.src} type="video/mp4" />
              Tarayıcınız video oynatmayı desteklemiyor.
            </video>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MasterclassPage;
