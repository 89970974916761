import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./MainPage.css";
import { Link } from "react-router-dom";

const MainPage = () => {
  return (
    <div className="main">
      <div className="left-container">
        <section className="carousel-section video-carousel">
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            showArrows={true}
            autoPlay
            infiniteLoop
          >
            <div>
              <video
                width="100%"
                controls
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                disablePictureInPicture
                controlsList="nodownload"
                poster="/imgs/ad-video-poster-1.jpg"
                preload="metadata"
              >
                <source src="/videos/1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div>
              <video
                width="100%"
                controls
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                disablePictureInPicture
                controlsList="nodownload"
                poster="/imgs/ad-video-poster-2.jpg"
                preload="metadata"
              >
                <source src="/videos/2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div>
              <video
                width="100%"
                controls
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                disablePictureInPicture
                controlsList="nodownload"
                poster="/imgs/ad-video-poster-3.jpg"
                preload="metadata"
              >
                <source src="/videos/3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div>
              <video
                width="100%"
                controls
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                disablePictureInPicture
                controlsList="nodownload"
                poster="/imgs/ad-video-poster-4.jpg"
                preload="metadata"
              >
                <source src="/videos/4.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Carousel>
        </section>
        <section className="description-section">
          <h2>Tüm dünya'da viral olan Çiçek Mum Türkiye'de !!</h2>
          <p>
            -Esnek, Bükülebilir Wax
            <br />
            -Büküldüğünde Kırılmaz <br />
            -Kolay Şekillendirilebilir <br />
            -Uzun Yanma Süresi <br />
            -Birçok Koku ve Renk seçenekleri ile sizlerle..
          </p>
          <Link to="/products">Ürün detaylarını inceleyin -{">"}</Link>
        </section>
      </div>

      <div className="right-container">
        <section className="carousel-section photo-carousel">
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            showArrows={true}
            autoPlay
            infiniteLoop
          >
            <div>
              <img
                src="/imgs/1.png"
                alt="Esnek Wax ile Çiçek Mum Fotoğrafı 1"
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                loading="lazy"
              />
            </div>
            <div>
              <img
                src="/imgs/2.png"
                alt="Esnek Wax ile Çiçek Mum Fotoğrafı 2"
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                loading="lazy"
              />
            </div>
            <div>
              <img
                src="/imgs/3.png"
                alt="Esnek Wax ile Çiçek Mum Fotoğrafı 3"
                title="Çiçek Mum, Elastik Wax, Esnek Mum, Esnek Wax, El ile Şekillendirilebilir Mum"
                loading="lazy"
              />
            </div>
          </Carousel>
        </section>
        <section className="description-section">
          <h2>Eğitim İçeriği</h2>
          <p>
            - Isı dereceleri,
            <br />
            - Renkledirme ve esans gramaj bilgileri, <br />
            - Kalıplama nasıl yapılır, <br />
            - El ile şekillendirme metodları, <br />
            - Aşama aşama tüm teknik bilgiler, <br />
            - Tedarikçi bilgileri, <br />
            - Hepsi videolu anlatımlı, <br />
            -Eğitim paketimize Yarım Kg (500 gr) Çiçek Mum dahildir.
          </p>
          <Link to="/masterclassintro">
            Eğitim detaylarını inceleyin -{">"}
          </Link>
        </section>
      </div>
    </div>
  );
};

export default MainPage;
